import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router';
import { RootState } from "../../../shared/state/root-reducer";
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../shared/state/store";
import { TypeOfCare, typeOfCare } from "../../../shared/model/type-of-care";
import {
    vobPatientPostBody,
    IocConfig,
    AssociatedClient,
} from '../../models/patient';
import { Utils } from "../../../shared/utils";
import classes from "./Launch.module.css";
import QueryStringList from "./QueryStringList";
import { clearFinPassStatus, clearPatient, clearPatientEncounterResult, clearPatientIocStatus, setPatient } from '../../state/vob-patient-slice';
import { clearFacilityPayers, clearVOB, setVOB } from '../../state/vob-slice';
import { clearEstimator, setEstimator } from "../../state/estimator-slice";
import { clearRiskAssessment } from '../../state/risk-assessment-slice';
import { checkClientPatientAccountId, checkClientPatientIOCId, getPatientDemographics, getPtRecord, savePatientPaymentProgram } from '../../../patient/state/patient-thunk';
import { patientService } from '../../../patient/services/patient-service';
import { getClientFacilities } from '../../../implementation-specialist/state/facility/implementation-facility-thunk';
import { createPatientIoc, getVobPatient, saveFinPassPatient, saveVobPatient } from '../../state/vob-patient-thunk';
import { Estimate, EstimateRequestConfig, PayorLocRateLos, SaveEstimatorPostBody } from '../../models/estimator';
import { callNewEstimateGet, callNewEstimatePost, callNewEstimatePut, getEstimate, saveEstimate } from '../../state/estimator-thunk';
import { getEstimatorFacilityPayers, getVob, getVobClassifications, saveVob } from '../../state/vob-thunk';
import { configGetLOC, configGetPayers } from '../../../admin-configuration/state/admin-configuration-thunk';
import { showErrorStatus } from '../../../security/state/user-slice';
import { checkPermissions } from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { UserRolePage } from '../../../security/model/user-role-page';
import { AxiosGetPayload } from '../../../shared/service/axios-get-payload';
import { crmInboundErrorMessages } from "../../../shared/enums";
import { ContactIncome, PhoneType, PrimaryContact } from '../../../shared/model/primary-contact';
import { PatientViewModel } from '../../../patient/components/models/patient-view-model';
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';
import { PayorRisk, TimingRisk, timingRiskNameLookup } from '../../../shared/model/timing-and-payor-risk';
import {Vob, VobPostBody, emptyVob, patientNotes} from '../../models/vob';
import { PatientRecordRes, setSelectedPatientV2 } from '../../../patient/state/patient-slice';
import { ClientCrm, ClientStatusCardViewModel, CrmObjectType } from '../../../shared/model/client-status-card';
import MobilePaymentsLoader from '../../../shared/components/mobile-payments-loader';
import moment from 'moment';
import { Estimate as NewEstimate, CrossoverSelectionEnum, QuoteMethod } from '@finpay/estimation-types';

const Launch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [debugMode, setDebugMode] = useState(false);
  const [estimateFeatureFlag, setEstimateFeatureFlag] = useState(false);
  const [queryParams, setQueryParams] = useState<any>();
  const [ptRecordResponse, setPtRecordResponse] = useState<any>();
  const [clientsLeadId, setClientsLeadId] = useState<any>();
  const [clientsAccountId, setClientsAccountdId] = useState<any>();
  const [currentClientId, setCurrentClientId] = useState<any>();
  const isLaunchInit = useRef(false);
  const leadDataLoaded = useRef(false);
  const iOCLoaded = useRef(false);
  const isStatePrefilled = useRef(false);
  const paramId: number = -2;

  const clientsPatientIOCIdFilter = useSelector((state: RootState) => {
    return state?.patientContext.clientsPatientIOCIdFilter;
  });

  const patientRecordInfo = useSelector((state: RootState) => {
    return state?.patientContext.inboundPtRecord;
  });

  const payers = useSelector((state: RootState) => {
    return state.adminContext.adminConfigurationContext?.payers;
  });

  const vobState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.vobContext;
  });

  const estimatorState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.estimatorContext?.estimator;
  });

  const allClients: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.allClients;
  });

  const userProfile = useSelector((state: RootState) => {
    return state.userContext.userProfile;
  });

  const clientsPatientAccountIdFilter = useSelector((state: RootState) => {
    return state?.patientContext.clientsPatientAccountIdFilter;
  });

  const vobClassificationsState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.vobContext?.vobClassifications;
  });

  const getPtRecordData = async (
    clientsPatientIOCId: string,
    clientId: number
  ) => {
    leadDataLoaded.current = true;
    const clientItem: ClientStatusCardViewModel = allClients.find((client:ClientStatusCardViewModel) =>  client.clientId === clientId)!
    let clientCrm: ClientCrm[] = clientItem?.clientCrm!;

    // Temporarily defaulting CRM to SalesForce if it's not in clientInfo
    if (!clientCrm || clientCrm?.length === 0 ) {
      clientCrm = [
        {
          preadmitCrmObjectType: {} as CrmObjectType,
          admitCrmObjectType: {} as CrmObjectType,
          crmType: {
            crmName: "Salesforce",
            crmTypeSlug: "sf",
          }
        },
      ];
      // return handleClientCRMError();
    }
    // setting feature flag for new estimate
    if (clientItem.convRunNum === 76) {
        setEstimateFeatureFlag(true);
    }
    const ptRecordRes: AxiosGetPayload = await dispatch(
      getPtRecord({ leadId: clientsPatientIOCId, clientId: clientId, crm: clientCrm[0] })
    );
    let errorMessage: string = crmInboundErrorMessages.misconfigured;
    if (ptRecordRes.error) {
      if (
        ptRecordRes.meta.arg.leadId === undefined ||
        ptRecordRes.payload === undefined
      ) {
        errorMessage = crmInboundErrorMessages.patientError;
      } else if (Number.isNaN(ptRecordRes.meta.arg.clientId)) {
        errorMessage = crmInboundErrorMessages.facilityError;
      }
      dispatch(showErrorStatus(errorMessage));
      navigate(`/admissions-advisor`);
    } else if (typeof ptRecordRes.payload === "string") {
      if (ptRecordRes.payload === "No facility to process.") {
        errorMessage = crmInboundErrorMessages.facilityError;
      }
      dispatch(showErrorStatus(errorMessage));
      navigate(`/admissions-advisor`);
    }
  };

  const checkExistingIOC = async (patientRecordInfo: PatientRecordRes) => {
      await dispatch(configGetPayers(paramId));
      await dispatch(getVobClassifications(paramId));
      dispatch(configGetLOC(paramId))
      const getFacilitiesRespone: any = await dispatch(
          getClientFacilities(currentClientId)
      );
      if(getFacilitiesRespone.error) {
        return navigate('/accessdenied');
      }

      if (
          patientRecordInfo.facilityId === null ||
          patientRecordInfo.facilityId === undefined
      ) {
          let buildingPatientRecord: any = Utils.deepClone(patientRecordInfo);
          buildingPatientRecord.facilityId =
              getFacilitiesRespone.payload?.groupedFacilities[0][0].facilityId;
          setPtRecordResponse(buildingPatientRecord);
      }
      if (patientRecordInfo.clientsPatientIOCId) {
          const keySearchPayload = {
              clientPatientIOCId: patientRecordInfo.clientsPatientIOCId,
              clientId: currentClientId,
          };
          await dispatch(checkClientPatientIOCId(keySearchPayload));
          iOCLoaded.current = true;
      }
  };

  const checkExistingClientAccountId = async () => {
      const ptRecord = Utils.deepClone(ptRecordResponse);
      if (ptRecord.clientsPatientAccountId) {
          const keySearchPayload = {
              clientsPatientAccountId: ptRecord.clientsPatientAccountId,
              clientId: currentClientId,
          };
          dispatch(checkClientPatientAccountId(keySearchPayload));
          iOCLoaded.current = true;
      } else {
        return handleGenericError(crmInboundErrorMessages.missingPatientAccountId);
      }
  };

  function mapToPatientState(patientDetails: any, ptRecord: any): vobPatientPostBody {
    return {
      firstName: patientDetails?.contact?.firstName,
      lastName: patientDetails?.contact?.lastName,
      birthDate: patientDetails?.contact?.birthDate,
      fpPatientId: patientDetails?.patientId,
      middleName: patientDetails?.contact.middleName || ptRecord?.patient?.contact.middleName,
      advisorPatientBody: {
        email: patientDetails?.contact?.email,
        primaryAddress: patientDetails?.contact?.primaryAddress,
        phoneNumber: patientDetails?.contact?.primPhoneNum,
      },
      clientId: ptRecord?.clientId,
    };
  }
  
  function formatVOBComments(ptRecordClone: any): patientNotes[] | undefined {
      return ptRecordClone.vob.comments?.length
          ? ptRecordClone.vob.comments.map((item: any) => ({
                noteText: item.comment,
                noteDt: '',
            }))
          : undefined;
  }

  function mapToNewEstimate(vobResponse: any, ptRecord: any, clonedEstimate: any, advisorPatientId: number, patientEncounterId?: string): NewEstimate {
    return {
      vobId: vobResponse?.payload.vobId!,
      advisorPatientId: advisorPatientId,
      clientId: ptRecord.clientId,
      facilityId: ptRecord.facilityId,
      patientEncounterId: patientEncounterId,
      description: "Blank Description",
      quoteMethod: QuoteMethod.AVGLOS,
      anticipatedAdmitDate: new Date(clonedEstimate.admissionDate),
      facilityLevelOfCare: [],
      priorCareLoC: [],
      isPlanYearCrossover: false,
      isActive: true,
      crossoverSelection: CrossoverSelectionEnum.BEFORE,
    }
  }

  function mapToIOCModel(
    vobPatientRecord: any,
    estimatorState: any,
    patientId: number,
    estimateId: number,
    ptRecord: any
  ): IocConfig {
    const pfrAmt = admissionsAdvisorUtils.getSelectedPFR(estimatorState);
    const ptRecordTimingRiskId = ptRecord.timingRisk?.timingRiskId || 1
    const currentTimingRisk: TimingRisk = {
      timingRiskId: ptRecordTimingRiskId,
      timingRiskName: timingRiskNameLookup(ptRecordTimingRiskId)!
    };
    const emptyPayorRisk: PayorRisk = {
      payorRiskId: 1,
      payorRiskName: "",
    };
    let currentTypeOfCare: TypeOfCare | undefined = undefined;
    if (ptRecord.typeOfCare?.typeOfCareId > 0) {
      currentTypeOfCare = typeOfCare.find(
          item => item.typeOfCareId === ptRecord.typeOfCare?.typeOfCareId
      );
    }
    const iocConfig: IocConfig = {
      paramId: 0,
      patientId: patientId,
      clientId: vobPatientRecord.vob.client.clientId!,
      facilityId: vobPatientRecord.vob.facility.facilityId!,
      pfrAmt: pfrAmt,
      timingRisk: currentTimingRisk,
      payorRisk: emptyPayorRisk,
      clientsPatientIOCId: clientsLeadId,
      clientsPatientAccountId: clientsAccountId,
      pfrEstimateId: estimateId,
      patientInsurance: ptRecord.patientInsurance,
      admissionDt: ptRecord.admissionDt,
      dischargeDt: ptRecord.dischargeDt,
      ...(currentTypeOfCare && { typeOfCare: currentTypeOfCare}),
      // temporarily hardcoding workflow status
      workflow: {
        workflowId: 2,
        workflowStatus: {
          workflowStatusId: 20,
        },
        workflowSubStatus: {
          workflowSubStatusId: 7,
        },
      },
    };
    return iocConfig;
  }

  function mapToPatientViewModel(
    vobPatientRecord: vobPatientPostBody,
    patientRecord: any
  ): PatientViewModel {
    const patientContact: PrimaryContact = {
      title: patientRecord.patient?.contact?.title || undefined!,
      firstName: vobPatientRecord.firstName,
      middleName: patientRecord.patient?.contact?.middleName || vobPatientRecord.middleName,
      lastName: vobPatientRecord.lastName,
      primaryAddress: {
        streetAddress1:
          vobPatientRecord.advisorPatientBody?.primaryAddress?.streetAddress1!,
        streetAddress2:
          vobPatientRecord.advisorPatientBody?.primaryAddress?.streetAddress2!,
        city: vobPatientRecord.advisorPatientBody?.primaryAddress?.city!,
        state: {
          stateId:
            vobPatientRecord.advisorPatientBody?.primaryAddress?.state
              ?.stateId!,
          stateCode:
            vobPatientRecord.advisorPatientBody?.primaryAddress?.state
              ?.stateCode!,
          stateName:
            vobPatientRecord.advisorPatientBody?.primaryAddress?.state
              ?.stateName!,
        },
        zipCode: vobPatientRecord.advisorPatientBody?.primaryAddress?.zipCode!,
        addressType: patientRecord.patient?.contact?.primaryAddress?.addressType || "Primary",
      },
      email: vobPatientRecord.advisorPatientBody?.email!,
      primPhoneNum: vobPatientRecord.advisorPatientBody?.phoneNumber!,
      primPhoneType: patientRecord.patient?.contact?.primPhoneType || PhoneType.home,
      secPhoneNum: patientRecord.patient?.contact?.secPhoneNum,
      secPhoneType: patientRecord.patient?.contact?.secPhoneType || PhoneType.mobile,
      leaveVMFl: patientRecord.patient?.contact?.leaveVMFl || false,
      smsConsentFl: patientRecord.patient?.contact?.smsConsentFl || false,
      ssnLast4: patientRecord.patient?.contact?.ssnLast4 || "",
      birthDate: patientRecord.patient?.contact?.birthDate || vobPatientRecord.birthDate,
      contactIncome: {
        isEmployed: patientRecord.patient?.contact?.contactIncome?.isEmployed || false,
        yearlySalary: patientRecord.patient?.contact?.contactIncome?.yearlySalary || 0,
        employer: patientRecord.patient?.contact?.contactIncome?.employer ||  "",
        yearsAtEmployer: patientRecord.patient?.contact?.contactIncome?.yearsAtEmployer || 0,
      } as ContactIncome,
    };

    const newPatient = {
      contact: patientContact,
      patientStatus: "NEW",
      clientId: patientRecord.clientId.toString(),
    } as PatientViewModel;

    return newPatient;
  }

  const setNewPatientRecord = async (existingPatient: boolean, existingPatientInfo: any | undefined, existingURLPath: string) => {
    let redirectURLPath: string = existingURLPath;
    const ptRecord = Utils.deepClone(ptRecordResponse);
    const urlParams = new URLSearchParams(queryParams);
    let formedParams = Object.fromEntries(urlParams);
    const combinedParams = {
      ...ptRecord,
      op: formedParams.op ? formedParams.op : undefined,
    };

    let clonedVob = Utils.deepClone(vobState);
    let clonedEstimate = Utils.deepClone(estimatorState);
    let patientState: vobPatientPostBody;
    let currentPatientId: number;
    let facilityPayers: any;
    let payer = emptyVob.payer;
    let plan = emptyVob.plan;
    formedParams.inboundPatient = "new";

    if (!existingPatient) {
      patientState = {
        firstName: ptRecord.patient?.contact?.firstName
          ? ptRecord.patient?.contact?.firstName
          : "",
        lastName: ptRecord.patient?.contact?.lastName
          ? ptRecord.patient?.contact?.lastName
          : "",
        birthDate: ptRecord.patient?.contact?.birthDate
          ? ptRecord.patient?.contact?.birthDate
          : "",
        fpPatientId: undefined,
        middleName: ptRecord.patient?.contact?.middleName,
        advisorPatientBody: {
          email: ptRecord.patient?.contact?.email,
          primaryAddress: ptRecord.patient?.contact?.primaryAddress,
          phoneNumber: ptRecord.patient?.contact?.primPhoneNum,
        },
        clientId: ptRecord.clientId,
      };
      // check for required patientient information before creating Patient records
      if (
        !patientState.firstName ||
        !patientState.lastName ||
        !patientState.birthDate
      ) {
       return handleGenericError(crmInboundErrorMessages.patientInfoError);
      } else {
        // Save Advisor Patient
        const aAPatientResponse: any = await dispatch(saveVobPatient(patientState));
        patientState.advisorPatientId = aAPatientResponse.payload.advisorPatientId;
    
        // Save FinPass Patient
        const newFinPassPatientPayload = mapToPatientViewModel(patientState, ptRecord);
        const fpPatientResponse = await dispatch(
          saveFinPassPatient({
            patient: newFinPassPatientPayload,
            advisorPatientId: aAPatientResponse.payload.advisorPatientId,
            isAddNew: true,
            patientId: undefined,
          })
        );
        dispatch(setSelectedPatientV2(fpPatientResponse?.payload)); // setting main FinPass Patient State to prefill Complete Patient Record
        patientState.fpPatientId = fpPatientResponse?.payload?.patientId;
        currentPatientId = fpPatientResponse?.payload?.patientId;
      }
    } else {
      patientState = {
        firstName: existingPatientInfo?.contact?.firstName,
        lastName: existingPatientInfo?.contact?.lastName,
        birthDate: ptRecord.patient?.contact?.birthDate || existingPatientInfo?.contact?.birthDate,
        fpPatientId: existingPatientInfo?.patientId,
        middleName: existingPatientInfo?.contact?.middleName || ptRecord.patient?.contact?.middleName,
        advisorPatientBody: {
          email: ptRecord.patient?.contact?.email,
          primaryAddress: ptRecord.patient?.contact?.primaryAddress,
          phoneNumber: ptRecord.patient?.contact?.primPhoneNum
        },
        advisorPatientId: existingPatientInfo.advisorPatientId,
        clientId: ptRecord?.clientId,
      };
      currentPatientId = existingPatientInfo.patientId;
    }

    const ptRecordVOB = admissionsAdvisorUtils.matchClassifications(ptRecord.vob, vobClassificationsState);

    const patientInsurance = ptRecord.patientInsurance?.[0];

    clonedVob.vob = admissionsAdvisorUtils.mapVOBs(clonedVob.vob, ptRecordVOB, patientInsurance)

    clonedVob.vob.client.clientId = ptRecord.clientId
      ? ptRecord.clientId
      : currentClientId;

    clonedVob.vob.facility.facilityId = ptRecord.facilityId
      ? ptRecord.facilityId
      : undefined;

    clonedVob.vob.patientNotes = formatVOBComments(ptRecord);

    if (ptRecord.facilityId) {
      const sssconfig: any = {
        paramId: paramId,
        masterListPayers: payers,
        facilityId: ptRecord.facilityId,
      };
      facilityPayers = await dispatch(getEstimatorFacilityPayers(sssconfig));
    }

    let matchingFacilityPayerByCode: any;

    if (ptRecord.vob?.payer?.clientCrmPayerCode && ptRecord.facilityId) {
        matchingFacilityPayerByCode = findMatchingPayerByCode(
            ptRecord.vob.payer.clientCrmPayerCode,
            facilityPayers.payload
        );
    }

    if (matchingFacilityPayerByCode) {
        payer = {
            ...matchingFacilityPayerByCode,
            payorId: matchingFacilityPayerByCode.cfgPayorId,
            payorName: matchingFacilityPayerByCode.facilityPayorName,
            payorCode: matchingFacilityPayerByCode.facilityPayorCode,
            inNetwork: matchingFacilityPayerByCode.inNetwork,
            payersList: matchingFacilityPayerByCode.facilityPayorPlans,
        };
        const planCodeOrName =
            ptRecord.vob?.plan?.clientCrmPlanCode ||
            ptRecord.vob?.plan?.planName;
        const matchingPayorPlan = findMatchingPlan(
            planCodeOrName,
            matchingFacilityPayerByCode.facilityPayorPlans
        );

        if (matchingPayorPlan) {
            plan = {
                ...matchingPayorPlan,
                payorPlanId: matchingPayorPlan.cfgPayorPlanId,
                planName: matchingPayorPlan.facilityPayorPlanName,
                planCode: matchingPayorPlan.facilityPayorPlanCode,
                facilityPlanId: matchingPayorPlan.facilityPayorPlanId,
                pdrRate: (matchingPayorPlan.facilityPayorLocRateLos as PayorLocRateLos)
                    ?.pdrRate,
                losDays: (matchingPayorPlan?.facilityPayorLocRateLos as PayorLocRateLos)
                    ?.losDays,
                payersPlanList: facilityPayers.payload,
            };
        }
    } else if (ptRecord.vob?.payer?.payerName) {
        const matchingFacilityPayerByName = findMatchingPayerByName(
            ptRecord.vob.payer.payerName,
            facilityPayers.payload
        );

        if (matchingFacilityPayerByName) {
            payer = {
                ...matchingFacilityPayerByName,
                payorId: matchingFacilityPayerByName.cfgPayorId,
                payorName: matchingFacilityPayerByName.facilityPayorName,
                payorCode: matchingFacilityPayerByName.facilityPayorCode,
                inNetwork: matchingFacilityPayerByName.inNetwork,
                payersList: matchingFacilityPayerByName.facilityPayorPlans,
            };

            const planCodeOrName =
                ptRecord.vob?.plan?.clientCrmPlanCode ||
                ptRecord.vob?.plan?.planName;
            const foundPayerPlan = findMatchingPlan(
                planCodeOrName,
                matchingFacilityPayerByName.facilityPayorPlans
            );

            if (foundPayerPlan) {
                plan = {
                    ...foundPayerPlan,
                    payorPlanId: foundPayerPlan.cfgPayorPlanId,
                    planName: foundPayerPlan.facilityPayorPlanName,
                    planCode: foundPayerPlan.facilityPayorPlanCode,
                    facilityPlanId: foundPayerPlan.facilityPayorPlanId,
                    pdrRate: (foundPayerPlan.facilityPayorLocRateLos as PayorLocRateLos)
                        ?.pdrRate,
                    losDays: (foundPayerPlan?.facilityPayorLocRateLos as PayorLocRateLos)
                        ?.losDays,
                    payersPlanList: facilityPayers.payload,
                };
            }
        }
    }

    clonedVob.vob.payer = payer;
    clonedVob.vob.plan = plan;

    // Save VOB
    const vobPostBody: VobPostBody = {
        advisorPatientId: patientState.advisorPatientId,
        fpClientId: ptRecord.clientId,
        fpClientFacilityId: ptRecord.facilityId,
        vobBody: {
            ...clonedVob.vob
        },
    };
    const vobResponse = await dispatch(saveVob(vobPostBody)); 

    const selectedClient = allClients.find(client => client.clientId === ptRecord.clientId);

    const estimateClientObject: AssociatedClient = {
      clientId: ptRecord.clientId,
      clientName: selectedClient?.clientName!
    }

    clonedEstimate.admissionDate = ptRecord.admissionDt
      ? ptRecord.admissionDt
      : null;

    const today = Utils.convertISODateAsUTC(new Date().toISOString());
    clonedEstimate.description = `No LOC - ${today}`;
    clonedEstimate.client = estimateClientObject;
    clonedEstimate.crmPrefilledLoC = combinedParams.estimate?.loc
        ? combinedParams.estimate?.loc
        : [];
    
    // Save Estimate
    let estimatePayLoad: SaveEstimatorPostBody = {
        paramId: paramId,
        estimateId: undefined,
        vobId: vobResponse?.payload.vobId!,
        advisorPatientId: patientState.advisorPatientId!,
        fpClientId: ptRecord.clientId,
        fpClientFacilityId: ptRecord.facilityId,
        estimateBody: clonedEstimate,
    };
    let savedEstimateResponse;

    // Checking feature flag for new estimate and called endpoint
    if (estimateFeatureFlag) {
        const postEstimateBody = mapToNewEstimate(
            vobResponse,
            ptRecord,
            clonedEstimate,
            patientState.advisorPatientId!
        );
        savedEstimateResponse = await dispatch(
            callNewEstimatePost(postEstimateBody)
        );
    } else {
        savedEstimateResponse = await dispatch(saveEstimate(estimatePayLoad));
    }

    // Save Instance of Care
    const iocConfig = mapToIOCModel(
      clonedVob,
      clonedEstimate,
      currentPatientId,
      savedEstimateResponse.payload.pfrEstimateId || savedEstimateResponse.payload.estimateId,
      ptRecord
    );

    patientState.birthDate = moment.utc(patientState.birthDate).format('YYYY-MM-DD'); // adjusting birthdate for vob input field
    const iocResponse: any = await dispatch(createPatientIoc(iocConfig));
    clonedEstimate.finPay.patientEncounterId =
        iocResponse.payload.patientEncounterId;
    clonedEstimate.finPay.pfrEstimateId =
        savedEstimateResponse.payload?.pfrEstimateId || savedEstimateResponse.payload?.estimateId;
    clonedEstimate.pfrEstimateId =
        savedEstimateResponse.payload?.pfrEstimateId || savedEstimateResponse.payload?.estimateId;
    estimatePayLoad.estimateId = clonedEstimate.finPay.pfrEstimateId;
    dispatch(setVOB({
      ...clonedVob.vob,
      urlParams: combinedParams
    }));
    
    // Checking feature flag for new estimate 
    if(estimateFeatureFlag) {
      const postEstimateBody = mapToNewEstimate(
        vobResponse,
        ptRecord,
        clonedEstimate,
        patientState.advisorPatientId!,
        clonedEstimate.finPay.patientEncounterId
      );
      dispatch(callNewEstimatePut({estimate: postEstimateBody, estimateId: clonedEstimate.pfrEstimateId}));      
      dispatch(setEstimator(clonedEstimate));
    } else {
      dispatch(saveEstimate(estimatePayLoad)); // updating estimate
      dispatch(setEstimator(clonedEstimate));
    }
    
    dispatch(
        setPatient({
            ...patientState,
            selectedIOC: iocResponse.payload,
        })
    );

    dispatch(clearPatientIocStatus());
    dispatch(clearFinPassStatus());
    dispatch(clearPatientEncounterResult());

    const estimasteRoleStatus = hasFinAdviorRole();
    if (redirectURLPath === "specialistDashboard" || !estimasteRoleStatus) {
      redirectURLPath = `/specialist/dashboard/${currentPatientId}`;
    }
    navigate(redirectURLPath);
  };

  const handleGenericError = async (errorMessage: string) => {
    await dispatch(showErrorStatus(errorMessage));
    navigate(`/admissions-advisor`);
  };

  const clearAAState = () => {
      dispatch(clearPatient());
      dispatch(clearVOB());
      dispatch(clearEstimator());
      dispatch(clearFacilityPayers());
      dispatch(clearRiskAssessment());
  };

  const findMatchingPayerByCode = (code: string, payerList: any): any | undefined =>
      payerList.find((payer: { facilityPayorCode: string; }) => payer.facilityPayorCode === code);

  const findMatchingPayerByName = (name: string, payerList: any[]): any | undefined =>
    payerList.find((payer) => new RegExp(name, 'i').test(payer.facilityPayorName));
  
  // const findMatchingPayerByName = (
  //     name: string,
  //     payerList: any[]
  // ): any | undefined =>
  //     payerList.find(payer => {
  //         if (name) {
  //             return new RegExp(name, 'i').test(payer.facilityPayorName);
  //         } else {
  //             return false;
  //         }
  //     });

  const findMatchingPlan = (codeOrName: string, planList: any[]): any | undefined =>
      planList.find((plan) => plan.facilityPayorPlanCode === codeOrName || new RegExp(codeOrName, 'i').test(plan.facilityPayorPlanName));

  // const findMatchingPlan = (
  //     codeOrName: string,
  //     planList: any[]
  // ): any | undefined =>
  //     planList.find(plan => {
  //         if (codeOrName) {
  //             return (
  //                 plan.facilityPayorPlanCode === codeOrName ||
  //                 new RegExp(codeOrName, 'i').test(plan.facilityPayorPlanName)
  //             );
  //         } else {
  //             return false;
  //         }
  //     });
    
  const getPatientEncounter = async (clientsPatientIOCIdFilter: number) => {
    let redirectURLPath: string = "/admissions-advisor";
    let ptRecordClone = Utils.deepClone(ptRecordResponse);
    const urlParams = new URLSearchParams(queryParams);
    let formedParams = Object.fromEntries(urlParams);
    const combinedParams = {
      ...ptRecordClone,
      op: formedParams.op ? formedParams.op : undefined,
    };
    const estimasteRoleStatus = hasFinAdviorRole();
    const instanceOfCare = await patientService.getPatientInstanceOfCare({
      patientId: 0,
      encounterId: clientsPatientIOCIdFilter,
    });
    let getAttachedEstimate: any;
    let vobResult: any;
    if (formedParams.op === "pay") {
        if(instanceOfCare.entity.workflow.workflowStatus.workflowStatusDesc === "Not Managed") {
                if (!estimasteRoleStatus) {
                    const iocRoleStatus = hasIOCRole();
                    if (iocRoleStatus) {
                        redirectURLPath = `/specialist/dashboard/${instanceOfCare.entity.patientId}`;
                    } else {
                       redirectURLPath = "/accessdenied";
                    }
                }
        } else {
          redirectURLPath = `/specialist/dashboard/${instanceOfCare.entity.patientId}`;
        }
    }
    if (instanceOfCare.entity.pfrEstimateId) {
      const config: EstimateRequestConfig = {
        paramId: paramId,
        estimationId: instanceOfCare.entity.pfrEstimateId,
      };
      if (estimateFeatureFlag) {
          getAttachedEstimate = await dispatch(callNewEstimateGet(config));
          if (getAttachedEstimate.error?.message) {
            return handleGenericError(crmInboundErrorMessages.newEstimateIdError);
          }
      } else {
          getAttachedEstimate = await dispatch(getEstimate(config));
      }

      if (getAttachedEstimate.payload.vobId) {
        const vobConfig: any = {
          paramId: paramId,
          vobId: getAttachedEstimate.payload.vobId,
        };
        vobResult = await dispatch(getVob(vobConfig));
      }
    }

    let estimateClone: Estimate = getAttachedEstimate?.payload?.estimateBody ? getAttachedEstimate.payload.estimateBody : Utils.deepClone(estimatorState);
    let selectedIoc = instanceOfCare.entity;
    let facilityId = selectedIoc.facilityId || ptRecordClone.facilityId;
    ptRecordClone.inboundPatient = undefined;

    const sssconfig: any = {
      paramId: paramId,
      masterListPayers: payers,
      facilityId: facilityId,
    };

    await dispatch(getEstimatorFacilityPayers(sssconfig)); //facilityPayers

    let savedClientId = currentClientId;
    if (facilityId && ptRecordClone) {
      let vob: Vob = vobResult?.payload.vobBody || emptyVob;

      const payer = vob.payer || emptyVob.payer;
      const plan = vob.plan || emptyVob.plan;
      let client = emptyVob.client;
      let facility = emptyVob.facility;

      // if existing VOB is missing client information we need to build it
      if(vob.client.clientId) {
        client = vob.client;
      } else {
        const selectedClient = allClients?.find(client => client.clientId === Number(savedClientId));
        client = {
          clientId: savedClientId,
          clientName: selectedClient?.clientName!,
          clientList: []
        };
      }

      // if existing VOB is missing facility information we need to build it
      if(vob.facility.facilityId) {
        facility = vob.facility;
      } else {
        const selectedClient = allClients?.find(client => client.clientId === Number(savedClientId));
        const clientFacilities = selectedClient?.clientFacilities?.find(cfacility => cfacility.facilityId === facilityId);
        facility = {
          facilityId: clientFacilities?.facilityId,
          facilityName: clientFacilities?.facilityName!
        };
      }

      const patientInsurance = ptRecordClone.patientInsurance?.[0];

      const ptRecordVOB = admissionsAdvisorUtils.matchClassifications(ptRecordClone.vob, vobClassificationsState);

      vob = admissionsAdvisorUtils.mapVOBs(vob, ptRecordVOB, patientInsurance)

      vob.patientNotes = formatVOBComments(ptRecordClone);

      vob.urlParams = combinedParams;
      vob.initialInboundPt = true;
      vob.payer = payer;
      vob.plan = plan;
      vob.client = client;
      vob.facility = facility;

      dispatch(setVOB(vob));

      const patientConfig: any = {
        paramId: paramId,
        patientId: vobResult?.payload.advisorPatientId || undefined,
      };

      let updatedState: vobPatientPostBody;
      const getPatientInfo = await dispatch(
        getPatientDemographics(instanceOfCare.entity.patientId)
      );
      const patientContact: any = getPatientInfo.payload;
  
      if (
          (!patientContact.patientDetails.contact.firstName &&
              !ptRecordClone.patient.contact.firstName) ||
          (!patientContact.patientDetails.contact.lastName &&
              !ptRecordClone.patient?.contact.lastName) ||
          (!patientContact.patientDetails.contact.birthDate &&
              !ptRecordClone.patient.contact.birthDate)
      ) {
          clearAAState();
          return handleGenericError(crmInboundErrorMessages.patientInfoError);
      }

      const prefilledBirthDate = ptRecordClone.patient?.contact?.birthDate || patientContact.patientDetails.contact.birthDate;
      const formattedDOB = moment.utc(prefilledBirthDate).format('YYYY-MM-DD');

      const newPatientState: vobPatientPostBody = {
        firstName: ptRecordClone.patient?.contact.firstName
          ? ptRecordClone.patient.contact.firstName
          : patientContact.patientDetails.contact.firstName,
        lastName:
          ptRecordClone.patient?.contact?.lastName ??
          patientContact?.patientDetails?.contact?.lastName,
        birthDate: formattedDOB,
        fpPatientId: instanceOfCare.entity.patientId,
        advisorPatientId:
          vobResult?.payload.advisorPatientId ||
          patientContact.patientDetails.advisorPatientId,
        middleName: ptRecordClone.patient?.contact.middleName
          ? ptRecordClone.patient.contact.middleName
          : patientContact.patientDetails.contact.middleName,
        advisorPatientBody: {
          email: ptRecordClone.patient?.contact.email
            ? ptRecordClone.patient.contact.email
            : patientContact.patientDetails.contact.email,
          primaryAddress: ptRecordClone.patient?.contact.primaryAddress
            ? ptRecordClone.patient.contact.primaryAddress
            : patientContact.patientDetails.contact.primaryAddress,
          phoneNumber: ptRecordClone.patient?.contact.primPhoneNum
            ? ptRecordClone.patient.contact.primPhoneNum
            : patientContact.patientDetails.contact.primPhoneNum
        },
      };
      if (patientConfig.patientId) {
        const getExistingVobPatient = await dispatch(
          getVobPatient(patientConfig)
        );
        updatedState = {
          ...getExistingVobPatient.payload,
          ...newPatientState,
        };
      } else {
        updatedState = {
          ...newPatientState,
        };
      }

      dispatch(
          setPatient({
              ...updatedState,
              selectedIOC: instanceOfCare.entity,
          })
      );

      if (!estimateClone.finPay.patientEncounterId || !estimateClone.pfrEstimateId) {
        const estimateId = instanceOfCare.entity.pfrEstimateId || undefined;
        const finPayObject = {
          patientEncounterId: instanceOfCare.entity.patientEncounterId,
          pfrEstimateId: estimateId,
        };
        estimateClone = { ...estimateClone, finPay: finPayObject, pfrEstimateId: estimateId };
      }

      // check to see if ptRecord has the LOC before we set/save the estimator state
      if (combinedParams?.estimate?.loc?.length > 0) {
          estimateClone = {
              ...estimateClone,
              crmPrefilledLoC: combinedParams.estimate.loc,
          };
          const advisorPatientId =
              vobResult?.payload.advisorPatientId ||
              patientContact.patientDetails.advisorPatientId;
          let estimatePayLoad: SaveEstimatorPostBody = {
              paramId: paramId,
              estimateId: estimateClone.pfrEstimateId,
              vobId: vob.vobId!,
              advisorPatientId: advisorPatientId,
              fpClientId: vob.client?.clientId,
              fpClientFacilityId: vob.facility?.facilityId,
              estimateBody: estimateClone,
          };

          if (estimateFeatureFlag) {
            const postEstimateBody = mapToNewEstimate(
                vob,
                ptRecordClone,
                estimateClone,
                advisorPatientId,
                instanceOfCare.entity.patientEncounterId
            );
            dispatch(
                callNewEstimatePut({
                    estimate: postEstimateBody,
                    estimateId: estimateClone.pfrEstimateId,
                })
            ); 
          } else {
            dispatch(saveEstimate(estimatePayLoad));
          }
      } 
      dispatch(setEstimator(estimateClone));
      isStatePrefilled.current = true;
      if(!estimasteRoleStatus) {
          redirectURLPath = `/specialist/dashboard/${instanceOfCare.entity.patientId}`;
      }
      navigate(redirectURLPath);
    }
  };

  function hasFinAdviorRole() {
      return checkPermissions(
          userProfile.userRole.userRolePages,
          RolePageNames.AdmissionsAdvisor,
          'VOB',
          false
      );
  }

  function hasIOCRole() {
      const hasUserRolePage = userProfile.userRole.userRolePages.some(
          (page: UserRolePage) => {
              return (
                  page?.sysRolePage?.rolePageName === RolePageNames.PatientRecords
              );
          }
      );
      return hasUserRolePage;
  }

  const loadExistingPatient = async (clientsPatientIOCIdFilter: number, redirectUrlPath: string) => {
      const instanceOfCare = await patientService.getPatientInstanceOfCare({
          patientId: 0,
          encounterId: clientsPatientIOCIdFilter,
      });
      const getPatientInfo: any = await dispatch(
          getPatientDemographics(instanceOfCare.entity.patientId)
      );
      // Checking to see if Patient Info contains an advisorPatientId
      if (getPatientInfo.payload.patientDetails?.advisorPatientId !== null) {
          // it does -- run set patient normally
          setNewPatientRecord(
              true,
              getPatientInfo.payload.patientDetails,
              redirectUrlPath
          );
      } else {
          // it does not -- must create advisor patient
          let updatedPatientDetails = Utils.deepClone(getPatientInfo.payload.patientDetails);
          const ptRecord = Utils.deepClone(ptRecordResponse);
          const patientState: vobPatientPostBody = mapToPatientState(
              getPatientInfo.payload.patientDetails,
              ptRecord
          );
          const aAPatientResponse: any = await dispatch(
              saveVobPatient(patientState)
          );
          // update Patient Details locally with new advisorPatientId
          updatedPatientDetails.advisorPatientId =
              aAPatientResponse.payload.advisorPatientId;
          // then update the existing FinPass patient with the advisorPatientId
          const finPassPatientPayload = mapToPatientViewModel(
              patientState,
              ptRecord
          );
          delete finPassPatientPayload.clientId;
          await dispatch(
              saveFinPassPatient({
                  patient: finPassPatientPayload,
                  advisorPatientId: aAPatientResponse.payload.advisorPatientId,
                  isAddNew: false,
                  patientId: getPatientInfo.payload.patientDetails?.patientId,
              })
          );
          // continue on to set patient
          setNewPatientRecord(true, updatedPatientDetails, redirectUrlPath);
      }
  }

  useEffect(() => {
    const isDebugMode = window.location.href.includes("debug=");
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const formedParams = Object.fromEntries(urlParams);

    setQueryParams(query);
    setDebugMode(isDebugMode);
    setCurrentClientId(formedParams.clientId);

    if (!isDebugMode && !isLaunchInit.current && allClients.length > 0) {
      isLaunchInit.current = true;
      getPtRecordData(
        formedParams.clientsPatientIOCId,
        Number(formedParams.clientId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClients]);

  useEffect(() => {
    if (Object.keys(patientRecordInfo!).length > 0 && leadDataLoaded) {
      setClientsLeadId(patientRecordInfo?.clientsPatientIOCId);
      setClientsAccountdId(patientRecordInfo?.clientsPatientAccountId);
      setPtRecordResponse(patientRecordInfo!);
      checkExistingIOC(patientRecordInfo!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientRecordInfo]);

  useEffect(() => {
      if (iOCLoaded && clientsPatientIOCIdFilter !== undefined) {
        let redirectUrlPath = "/admissions-advisor"
          switch (clientsPatientIOCIdFilter.length) {
              case 0:
                  checkExistingClientAccountId();
                  break;
              case 1:
                  getPatientEncounter(clientsPatientIOCIdFilter[0]);
                  break;
              default:
                  handleGenericError(crmInboundErrorMessages.clientPatientIOCId);
                  break;
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsPatientIOCIdFilter]);

  useEffect(() => {
      if (clientsPatientAccountIdFilter !== undefined) {
          const urlParams = new URLSearchParams(queryParams);
          const formedParams = Object.fromEntries(urlParams);
          const estimasteRoleStatus = hasFinAdviorRole();
          const iocRoleStatus = hasIOCRole();
          let redirectUrlPath = "/admissions-advisor"
          switch (clientsPatientAccountIdFilter.length) {
              case 0:
                  if (formedParams.op === 'pay') {
                      if (!estimasteRoleStatus) {
                          if (iocRoleStatus) {
                            redirectUrlPath = "specialistDashboard";
                          } else {
                            redirectUrlPath = "/accessdenied";
                          }
                      }
                  } 
                  setNewPatientRecord(false, undefined, redirectUrlPath);
                  break;
              default:
                  if (formedParams.op === "pay") {
                    if (!estimasteRoleStatus) {
                        if (iocRoleStatus) {
                            redirectUrlPath = "specialistDashboard";
                        } else {
                            redirectUrlPath = "/accessdenied";
                        }
                    }
                }
                loadExistingPatient(clientsPatientAccountIdFilter[0], redirectUrlPath);
                break;
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsPatientAccountIdFilter]);


  return (
    <>
      {debugMode ? (
        <Paper className={classes.splashStyle}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ paddingTop: "1em", paddingLeft: "3em" }}
          >
            <QueryStringList urlParams={queryParams} />
          </Grid>
        </Paper>
      ) : (
        <Paper className={classes.splashStyle}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ paddingTop: "1em", paddingLeft: "3em" }}
          >
            <MobilePaymentsLoader showLoader={true} customMessage={crmInboundErrorMessages.loadingMsg} />
          </Grid>
      </Paper>
      )}
    </>
  );
};
export default Launch;
