import { DialogActionButton, saveCallbackStatus, TextField, Toggle } from '@finpay-development/shared-components';
import {
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { PatientViewModel } from '../../../patient/components/models/patient-view-model';
import { clearStatus, setSelectedEncounter, setPatientEncounters } from '../../../patient/state/patient-slice';
import { showErrorStatus } from '../../../security/state/user-slice';
import { statesList } from '../../../shared/misc/us-states';
import { ContactIncome, PhoneType, PrimaryContact } from '../../../shared/model/primary-contact';
import { State } from '../../../shared/model/state';
import { Payment } from '../../../patient/components/models/payment';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { Utils } from '../../../shared/utils';
import { showStatus } from '../../../security/state/user-slice';
import { completePatientModalSchema } from '../../../shared/validation/schemas';
import { Estimate, EstimatorBody, PfrEstimator, SaveEstimatorPostBody, apiStatus } from '../../models/estimator';
import { IocConfig } from '../../models/patient';
import { setEstimator, clearSaveEstimatorStatus } from '../../state/estimator-slice';
import { clearFinPassStatus, clearPatientIocStatus, setPatient } from '../../state/vob-patient-slice';
import { saveEstimate, saveAdditionalEstimate } from '../../state/estimator-thunk';
import { createPatientIoc, makePayment, saveFinPassPatient } from '../../state/vob-patient-thunk';
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';
import { PatientNotes, emptyPatientNote } from "../../../patient/components/models/patient-note";
import { createPatientNote, savePatientPaymentProgram } from '../../../patient/state/patient-thunk';
import { createCommentSuccesMessages, crmLabels, TypesOfCare } from '../../../shared/enums';
import { timingRisk, payorRisk, TimingRisk, PayorRisk } from "../../../shared/model/timing-and-payor-risk";
import { TypeOfCare, typeOfCare } from "../../../shared/model/type-of-care";
import { LoadingOverlay } from "@finpay-development/shared-components";
import { EstDetails } from './estimation-details';
import { workFlowStatus } from '../../../patient/components/models/workflow-status';
import { TakePaymentView } from '../../../shared/components/take-payment-modal/take-payment-view';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import { PatientPaymentSchedule } from 'src/patient/components/models/patient-payment.schedule';
import { PatientPaymentProgram } from 'src/patient/components/models/patient-payment-program';
import { find as _find } from 'lodash';
import { patientService } from '../../../patient/services/patient-service';
import { ClientCrm, ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import { CompletePatientComment } from 'src/shared/model/crm';

interface EstCompletePatientRecordModalProps {
  open: boolean;
  handlePatientRecordModalCancel: () => void;
  handlePatientRecordModalSubmit: (estimation:EstimatorBody) => void;
}

interface NameValue {
  name: string;
  value: string;
}

export function EstCompletePatientRecordModal(
  props: EstCompletePatientRecordModalProps
) {
  const {
    open,
    handlePatientRecordModalCancel,
    handlePatientRecordModalSubmit,
  } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [displayPFRTotalError, setDisplayPFRTotalError] = useState(false);
  const [selectedState, setSelectedState] = useState<State>();
  const [isAddNew, setIsAddNew] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePaymentReadyStatus, setStripePaymentReadyStatus] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [finadvisorTakePayment, setFinadvisorTakePayment] = useState<boolean | undefined>(false);

  const paramId: number = -2;

  const PatientTitles: NameValue[] = [
    { name: "Mrs.", value: "Mrs." },
    { name: "Mr.", value: "Mr." },
    { name: "Ms.", value: "M." },
  ];

  const state = {
    clients: useSelector(
        (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.filteredByIsActive
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    selectedFinPassPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.selectedFinPassPatient
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.patientContext.errorMessage
    ),
    patientEncounterResult: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.patientEncounterResult
    ),
    isLoadingPatientIoc: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.isPatientLoading.createPatientIoc
    ),
    iocSaveStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.patientIocStatus
    ),
    saveFinPassPatientStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.saveFinPassPatientStatus
    ),
    finPassErrorMessage: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.errorMessage
    ),
    savedEstimatesLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.isLoadingSaveEstimates
    ),
    savedEstimatesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.saveEstimateStatus
    ),
    savedEstimationsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.savedEstimations
    ),
    stripeToken: useSelector(
      (state: RootState) =>
        state.patientContext?.patientTakePaymentTokens?.token
    ),
    selectedIOC: useSelector((state: RootState) => state.admissionsAdvisorContext.vobPatientContext.patient.selectedIOC),
    allClients: useSelector(
      (state: RootState) =>
      state.implementationContext?.implementationSpecialistClient.allClientsWithFacillities
    ),
    selectedPatientEncounters: useSelector(
      (state: RootState) =>
        state.patientContext?.selectedPatient.patientEncounters
    ),
    selectedFacilityRiskData: useSelector(
      (state: RootState) => 
        state.admissionsAdvisorContext.riskAssessmentContext.selectedFacilityRiskData

    ),
    selectedPatient: useSelector(
      (state: RootState) => state.patientContext.selectedPatient
    )
  };

  const { estimatorState, vobState, vobPatientState, selectedFinPassPatient, patientEncounterResult, isLoadingPatientIoc, iocSaveStatus, errorMessage, saveFinPassPatientStatus, finPassErrorMessage, savedEstimatesLoadingState, savedEstimatesStatusState, savedEstimationsState, stripeToken, selectedIOC, clients, allClients, selectedPatientEncounters, selectedFacilityRiskData, selectedPatient } = state;

  useEffect(() => {
    const handleOperations = async () => {
      try {
        if (saveFinPassPatientStatus === saveCallbackStatus.success) {
          dispatch(clearFinPassStatus())
          
          if (estimatorState.pfrEstimateId) {
            saveIOC()
          } else {
            saveCurrentEstimate()
          }
        }

        if (!savedEstimatesLoadingState && savedEstimatesStatusState === apiStatus.success) {
          dispatch(clearSaveEstimatorStatus())
          saveIOC()
        }

        if (!isLoadingPatientIoc && iocSaveStatus === saveCallbackStatus.success) {
          dispatch(clearPatientIocStatus())

          const newEncounterPayload = mapToPatientEncounter(patientEncounterResult);
          const clientItem = allClients?.find(client => client.clientId === estimatorState.client.clientId);
          const clientCrm = clientItem?.clientCrm || [];

          if (patientEncounterResult?.patientEncounterId) {
            let estimatorStateCopy = Utils.deepClone(estimatorState);
            estimatorStateCopy = setEstimatorDescription(estimatorStateCopy);
            estimatorStateCopy.finPay.patientEncounterId = patientEncounterResult.patientEncounterId;
            estimatorStateCopy.finPay.sentDateTime = new Date().toISOString();
            dispatch(setEstimator(estimatorStateCopy))

            let comment = emptyPatientNote;
            const formedNotes = completePatientFormik.values.isWarmTransfer.concat(' - ', completePatientFormik.values.noteText);
            const currentDate = new Date();

            comment.completionDueDt = '';
            comment.patientEncounterId = patientEncounterResult.patientEncounterId;
            comment.patientId = selectedFinPassPatient?.patientId;
            comment.noteDt = currentDate.toISOString();
            comment.noteText = formedNotes;
            comment.isReminder = false;

            if (completePatientFormik.values.isPayAtFacility && Number(completePatientFormik.values.payAtFacilityAmt) > 0) {
              const paidAtFacilityNote = `$${Number(completePatientFormik.values.payAtFacilityAmt)} to be collected at facility`;
              comment.noteText = formedNotes.concat(' - ', paidAtFacilityNote);
            }
  
            try {
              await dispatch(createPatientNote({
                message: createCommentSuccesMessages.comment,
                comment,
                showNotify: false
              })).unwrap();
  
              dispatch(setPatient({ ...vobPatientState, selectedIOC: newEncounterPayload }));
  
              const completePatientComment = {
                client: { clientId: vobState.client?.clientId, clientName: vobState.client?.clientName },
                facility: { facilityId: vobState.facility?.facilityId, facilityName: vobState.facility?.facilityName },
                clientsPatientAccountId: newEncounterPayload.clientsPatientAccountId,
                clientsPatientIocId: newEncounterPayload.clientsPatientIOCId,
                vob: vobState,
                estimate: estimatorStateCopy,
                patientEncounter: newEncounterPayload,
                riskAssessment: selectedFacilityRiskData
              };
              const isIntegrationEnabled = admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
              if (isIntegrationEnabled && clientCrm?.[0]?.isCrmCommentEnabled && completePatientComment.clientsPatientIocId) {
                const integrationAddCommentResponse = await patientService.integrationAddComment({
                  comment: completePatientComment,
                  crmName: clientCrm[0].crmType.crmName
                });
                if(integrationAddCommentResponse.hasErrors) throw new Error(integrationAddCommentResponse.errorMessage);
              }
              if (completePatientFormik.values.isPayNow && selectedFinPassPatient?.patientId) {
                const totalPaymentAmount = Number(completePatientFormik.values.payNowAmt);
                const paymentInfo = await makePreConvertedPayment(totalPaymentAmount, newEncounterPayload.patientEncounterId, clientCrm);
                await dispatch(makePayment({payment: paymentInfo, showNotify: false})).unwrap();
              } else {
                const isIntegrationEnabled = admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
                if (isIntegrationEnabled) {
                    await patientService.integrationUpdate({
                        patientEncounterId:
                            newEncounterPayload.patientEncounterId,
                        crmName:
                            clientCrm.length > 0
                                ? clientCrm[0].crmType.crmName
                                : undefined,
                        patientId: selectedFinPassPatient?.patientId,
                    });
                }
              }
  
  
              setIsLoading(false);
              setEnableSaveButton(true);
              dispatch(showStatus(`Successfully ${isAddNew ? 'created' : 'updated'} patient, program, comment, etc.`));
              handleSaveCallback(true, estimatorStateCopy);
            } catch (error: any) {
              setIsLoading(false);
              setEnableSaveButton(true);
              dispatch(showErrorStatus(error.message));
              dispatch(clearStatus());
              return;  // Exit if any operation fails
            }
          } else {
            handleSaveCallback(false);
            setIsLoading(false);
            setEnableSaveButton(true);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
        handleSaveCallback(false);
      }
    };

    handleOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFinPassPatientStatus, savedEstimatesLoadingState, isLoadingPatientIoc, iocSaveStatus]);

  useEffect(() => {
    if (vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.state) {
      const stateToSave = statesList?.find(
        (usState) => usState?.stateCode === vobPatientState?.patient?.advisorPatientBody?.primaryAddress.state.stateCode
      );
      setSelectedState(stateToSave)
    }

    const currentClient = _find(clients, (client) => (client?.clientId === estimatorState.client.clientId));
    setFinadvisorTakePayment(currentClient?.finadvisorTakePayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWorkflow = ({pfrAmt, payAtFacilityAmt, payNowAmt, selectedIOCWorkflowStatusId}: Record<string, any>)=> {

    //determining workflow based on amount to be paid at facility. for vt dashboard
    //todo: if the Take Payment Now functionality gets built out on Levels of Care, we need to include that payment amount to the below calculation
    let workflow: Record<string, any> | undefined;

    if(
        payAtFacilityAmt &&
        ((pfrAmt - payNowAmt) === payAtFacilityAmt)
    ){

      //if the remaining pfr amount is to be paid at the facility
      workflow = workFlowStatus.preconvert.find((workflow: any)=>{
        return workflow.workflowStatus.workflowStatusName === "Follow Up Needed" && workflow.workflowSubStatus.workflowSubStatusName === 'Pending Payment at Facility'
      })

    } else if(
        payAtFacilityAmt &&
        ((pfrAmt - payNowAmt - payAtFacilityAmt) > 0)
    ){
      //if the remaining pfr amount is to be paid at the facility and through a payment plan
      workflow = workFlowStatus.preconvert.find((workflow: any)=>{
        return workflow.workflowStatus.workflowStatusName === "New" && workflow.workflowSubStatus.workflowSubStatusName === 'Pending Payment at Facility'
      })

    } else if(selectedIOCWorkflowStatusId === 20){

       // If IOC was created with the "Not Managed" workflow as is the case with Regard
       // we set the workflow to "New - None" if there isn't a workflow specified.
      workflow = workFlowStatus.preconvert.find((workflow: any)=>{
        return workflow.workflowStatus.workflowStatusName === "New" && workflow.workflowSubStatus.workflowSubStatusName === "None"
      })

    }

    return workflow;
  }

  const makePreConvertedPayment = async (totalPaymentAmount: number, encounterId: number, clientCrm: ClientCrm[]) => {

    const paymentProgram = mapToPatientPaymentProgram();
    const paymentProgramResponse = await dispatch(savePatientPaymentProgram(
      {
        paymentProgram: paymentProgram,
        patientId: selectedFinPassPatient?.patientId,
        encounterId: patientEncounterResult?.patientEncounterId!,
        showNotify: false,
      }
    ))
    const paymentInfo = mapToPayment(totalPaymentAmount, paymentProgramResponse.payload);

    // call RT udpate
    const isIntegrationEnabled = admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
    if (isIntegrationEnabled) {
        patientService.integrationUpdate({
            patientEncounterId: encounterId,
            crmName:
                clientCrm.length > 0 ? clientCrm[0].crmType.crmName : undefined,
            patientId: selectedFinPassPatient?.patientId,
        });
    }

    return paymentInfo;
  }

  const mapToPatientEncounter = (patientEncounter: any) => {
      const {
          eceSent,
          authorizationDocumentStatus,
          pfrEstimateId,
          isWarmTransferCompleted,
          convertDt,
          typeOfCare,
          finClearanceStatus,
          vobDate,
          client,
          hasChampions,
          ...rest
      } = patientEncounter;

      const realTimeEncounterPayload: any = {
          ...(eceSent && {eceSent}),
          ...(pfrEstimateId && {pfrEstimateId}),
          ...(isWarmTransferCompleted && {isWarmTransferCompleted}),
          ...(convertDt && {convertDt}),
          ...(typeOfCare?.typeOfCareId && {typeOfCare}),
          ...(finClearanceStatus && {finClearanceStatus}),
          ...(vobDate && {vobDate}),
          hasChampions: hasChampions || false,
          ...rest,
      };

      delete realTimeEncounterPayload.authorizationDocumentStatus;
      delete realTimeEncounterPayload.client;
      delete realTimeEncounterPayload.pfrEstimateId;

      return realTimeEncounterPayload;
  };

  const saveIOC = async () => {
    // let estimatorStateCopy: EstimatorBody = Utils.deepClone(estimatorState);
    const pfrAmt = admissionsAdvisorUtils.getSelectedPFR(estimatorState);
    // vob data
    // const inNetwork = vobState.payer.inNetwork;
    // const coinsuranceAmt = inNetwork? vobState?.inNetwVobClassifications![0].coInsurance : vobState?.ooNetwVobClassifications![0].coInsurance;
    // const copayAmt = inNetwork? vobState?.inNetwVobClassifications![0].coPay : vobState?.ooNetwVobClassifications![0].coPay;
    // const deductibleAmt = inNetwork? vobState?.inNetwDeductibleRemaining : vobState.ooNetwDeductibleRemaining;
    // const deductibleIncInOOPMaxFl = inNetwork? vobState?.inNetwOopIncluded : vobState.ooNetwOopIncluded;
    // const familyDeductibleAmt = inNetwork? vobState?.inNetwFamilyDeductibleRemaining : vobState?.ooNetwFamilyDeductibleRemaining;
    // const familyOOPMaxAmt = inNetwork? vobState?.inNetwFamilyOopMax : vobState?.ooNetwFamilyOopMax;
    // const familyRemainingOOPMaxAmt = inNetwork? vobState?.inNetwFamilyOopMaxRemaining : vobState?.ooNetwFamilyOopMaxRemaining;
    // const familyRemDeductibleAmt = inNetwork? vobState?.inNetwFamilyDeductibleRemaining : vobState?.ooNetwFamilyDeductibleRemaining;
    // const isSubjectToCopay = inNetwork? (vobState?.inNetwVobClassifications![0].coPaySelection  === 'y'? true : false) : (vobState?.ooNetwVobClassifications![0].coPaySelection  === 'y'? true : false);
    // const maxDays = inNetwork? vobState?.inNetwVobClassifications![0].maxDays : vobState?.ooNetwVobClassifications![0].maxDays;
    // const oopmaxAmt = inNetwork? vobState?.inNetwOopMax : vobState?.ooNetwOopMax;
    // const remainingOOPMaxAmt = inNetwork? vobState?.inNetwOopMaxRemaining : vobState?.ooNetwOopMaxRemaining;
    // const remDeductibleAmt = inNetwork? vobState?.inNetwDeductibleRemaining : vobState?.ooNetwDeductibleRemaining;
    const foundTimingRisk = timingRisk.find((timing: TimingRisk) => timing.timingRiskId === completePatientFormik.values.timingRisk);
    const foundPayorRisk = payorRisk.find((payor: PayorRisk) => payor.payorRiskId === completePatientFormik.values.payorRisk);
    const levelsOfCare = estimatorState.selectedLevelsOfCare.map(loc => loc.facilityLevelOfCareName).join(", ");
    const truncLevelsOfCare = levelsOfCare.length > 128 ? levelsOfCare.slice(0, 128) : levelsOfCare;

    let defaultTypeOfCare : TypeOfCare;
    if(estimatorState?.serviceLevel === TypesOfCare.opt) {
        defaultTypeOfCare = typeOfCare[1];
    } else {
        defaultTypeOfCare = typeOfCare[0];
    }

    const workflow: Record<string, any> | undefined = getWorkflow({
      pfrAmt,
      payAtFacilityAmt: Number(completePatientFormik.values.payAtFacilityAmt),
      payNowAmt: Number(completePatientFormik.values.payNowAmt),
      selectedIOCWorkflowStatusId: selectedIOC?.workflow?.workflowStatus?.workflowStatusId,
    })

    const iocConfig: IocConfig = {
      paramId: 0,
      patientId: selectedFinPassPatient?.patientId,
      clientId: estimatorState.client.clientId!,
      facilityId: estimatorState.facility.facilityId!,
      pfrAmt: pfrAmt,
      pfrEstimateId: estimatorState.pfrEstimateId,
      timingRisk: foundTimingRisk,
      payorRisk: foundPayorRisk,
      levelOfCare: truncLevelsOfCare,
      typeOfCare: defaultTypeOfCare,
      ...workflow && {
        workflow
      }
    }

    // if (!vobState?.selfPay) {
    //   iocConfig.patientInsurance = [
    //     {
    //       coinsuranceAmt: coinsuranceAmt!,
    //       coinsuranceType: "$",
    //       copayAmt: copayAmt!,
    //       deductibleAmt: deductibleAmt!,
    //       deductibleIncInOOPMaxFl: deductibleIncInOOPMaxFl!,
    //       familyDeductibleAmt: familyDeductibleAmt!,
    //       familyOOPMaxAmt: familyOOPMaxAmt!,
    //       familyRemainingOOPMaxAmt: familyRemainingOOPMaxAmt!,
    //       familyRemDeductibleAmt: familyRemDeductibleAmt!,
    //       groupNumber: vobState?.groupNum,
    //       insuranceCompanyName: vobState?.payer.payorName!,
    //       insurancePlanName: vobState?.plan.planName!,
    //       isInNetwork: inNetwork!,
    //       isSubjectToCopay: isSubjectToCopay,
    //       maxDays: maxDays!,
    //       oopmaxAmt: oopmaxAmt!,
    //       policyNumber: vobState?.policyNum,
    //       remainingOOPMaxAmt: remainingOOPMaxAmt!,
    //       remDeductibleAmt: remDeductibleAmt!,
    //       sortOrder: null
    //     }
    //   ]
    // }

    //update estimate in state
    let estimatorStateCopy: Estimate = Utils.deepClone(estimatorState);
    // check if finPay section exists
    if (estimatorStateCopy.finPay) {
      estimatorStateCopy.finPay.patientId = selectedFinPassPatient?.patientId!;
    } else {
      Object.assign(estimatorStateCopy, {
        finPay: {
          patientId: selectedFinPassPatient?.patientId,
        },
      });
    }
    dispatch(setEstimator(estimatorStateCopy));
    
    const totalSurchargeRate = admissionsAdvisorUtils.calculateTotalSurchargeRate(estimatorStateCopy.selectedLevelsOfCare);
    iocConfig.totalSurchargeRate = totalSurchargeRate; 
    // create/update instance of care
    const iocResponse = await dispatch(createPatientIoc(iocConfig));
    dispatch(
      setSelectedEncounter(iocResponse?.payload)
    );
    //if selectedPatientEncounters is 1 and encounter id = 0 and patient id = 0
    if (selectedPatientEncounters.length === 1 && selectedPatientEncounters[0].patientEncounterId === 0 && selectedPatientEncounters[0].patientId === 0) {
      dispatch(setPatientEncounters([{...iocResponse?.payload}]));
    }
    // save estimate if needed
    additionalEstimateSave(iocResponse?.payload)
  }

  const saveCurrentEstimate = () => {
    let estimatorStateCopy = Utils.deepClone(estimatorState);
    // going to save estimate - make sure has description
    estimatorStateCopy = setEstimatorDescription(estimatorStateCopy);
    let estimatePayLoad: SaveEstimatorPostBody = {
      paramId: paramId,
      estimateId: estimatorStateCopy.pfrEstimateId,
      vobId: vobState.vobId!,
      advisorPatientId: vobPatientState.patient.advisorPatientId!,
      fpClientId: vobState.client?.clientId,
      fpClientFacilityId: vobState.facility?.facilityId,
      estimateBody: estimatorStateCopy,
    };
    dispatch(setEstimator(estimatorStateCopy));
    dispatch(saveEstimate(estimatePayLoad));
  }

  const handleStateChange = (e: Event) => {
    const selectedStateCode = (e.target as HTMLInputElement).value;
    const stateToSave = statesList?.find(
      (usState) => usState?.stateCode === selectedStateCode
    );
    setSelectedState(stateToSave)
  }

  const additionalEstimateSave = (savedIOCResponse: any) => {
    if(savedEstimationsState.length > 0) {
      let filteredEstimations: PfrEstimator[] = [];
      if(vobState?.vobId) {
        // we need to loop through and get all that match VOB
        filteredEstimations = savedEstimationsState.filter(
          (estimation) =>
            estimation.vobId === vobState.vobId
        );

        // now loop through filteredEstimations and see if there are any with out an encounter
        filteredEstimations.forEach(async (estimation) => {
          const patientEncounterId = estimation.estimateBody.finPay?.patientEncounterId;
          let currentEstimate: PfrEstimator = estimation;
          if (patientEncounterId === undefined || patientEncounterId === null || typeof patientEncounterId !== 'number') {
            // I need to save this estimate with the finpay.patientEncounterId added. 
            const finPayObject = {
              patientEncounterId: savedIOCResponse.patientEncounterId,
              pfrEstimateId: estimation.pfrEstimateId,
            };
            // Update estimateBody with the finPayObject
            currentEstimate = {
              ...currentEstimate,
              estimateBody: {
                ...currentEstimate.estimateBody,
                finPay: finPayObject,
              },
            };
            let estimatePayLoad: SaveEstimatorPostBody = {
              paramId: paramId,
              estimateId: estimation.pfrEstimateId,
              vobId: estimation.vobId!,
              advisorPatientId: estimation.advisorPatientId!,
              fpClientId: estimation.estimateBody.client.clientId,
              fpClientFacilityId: estimation.estimateBody.facility.facilityId,
              estimateBody: currentEstimate.estimateBody,
            };
            await dispatch(saveAdditionalEstimate(estimatePayLoad));
          }
        });
      }
    }
  }

  // saves as new Finpass patient
  async function handleSave() {
    setIsLoading(true);
    setEnableSaveButton(false);
    dispatch(clearStatus());
    const patientContact: PrimaryContact = {
      title: completePatientFormik.values.patientTitle,
      firstName: completePatientFormik.values.patientFirstName,
      middleName: completePatientFormik.values.patientMI,
      lastName: completePatientFormik.values.patientLastName,
      primaryAddress: {
        streetAddress1: completePatientFormik.values.streetAddress1,
        streetAddress2: completePatientFormik.values.streetAddress2,
        city: completePatientFormik.values.city,
        state: {
           stateId: selectedState?.stateId!,
           stateCode: selectedState?.stateCode!,
           stateName: selectedState?.stateName!
        },
        zipCode: completePatientFormik.values.zip,
        zipCode4: "",
        addressType: "Primary"
      },
      email: completePatientFormik.values.email,
      primPhoneNum: completePatientFormik.values.phone1,
      primPhoneType: completePatientFormik.values.phone1Home ? PhoneType.mobile : PhoneType.home,
      secPhoneNum: completePatientFormik.values.phone2,
      secPhoneType: completePatientFormik.values.phone2Home ? PhoneType.mobile : PhoneType.home,
      leaveVMFl: completePatientFormik.values.leaveVMFl,
      smsConsentFl: completePatientFormik.values.smsConsentFl,
      ssnLast4: "",
      birthDate: vobPatientState.patient.birthDate!,
      contactIncome: {
        isEmployed: false,
        yearlySalary: estimatorState.financialAssistance?.patientIncomeExpenses?.income? estimatorState.financialAssistance.patientIncomeExpenses.income * 12 : 0,
        employer: "",
        yearsAtEmployer: 0,
      } as ContactIncome
    }

    const newPatient = {
      contact: patientContact,
      patientStatus: "NEW",
      clientId: estimatorState.client.clientId,
    } as PatientViewModel;

    const advisorPatientId = vobPatientState.patient.advisorPatientId === undefined ? -1 : vobPatientState.patient.advisorPatientId;
    const patientId = vobPatientState.patient.advisorPatientId === undefined ? selectedFinPassPatient?.patientId : vobPatientState.patient.fpPatientId;

    // check if already associated to a finpassPatientId then call PUT
    let isNewPatient: boolean = true;
    if (patientId !== null) {
      isNewPatient = false;
      delete newPatient.clientId
    }
    setIsAddNew(isNewPatient);
    await dispatch(saveFinPassPatient({ patient: newPatient, advisorPatientId: advisorPatientId, isAddNew: isNewPatient, patientId: patientId}));
  }

  function handleSaveCallback(saveSuccessful:boolean, estimation?: EstimatorBody) {
    if (saveSuccessful) {
      handlePatientRecordModalSubmit(estimation!);
    } else {
      let actualMessage= errorMessage.length > 0 ? errorMessage : finPassErrorMessage;
      dispatch(showErrorStatus(actualMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handlePatientRecordModalCancel();
  }

  const completePatientSchema = Yup.object(completePatientModalSchema);

  function checkIsFormValid(value: any) {
    completePatientSchema
      .validate(value)
      .then(() => {
      //todo: if the Take Payment Now functionality gets built out on Levels of Care, we need to include that payment amount to the below calculation
        const pfrAmt = admissionsAdvisorUtils.getSelectedPFR(estimatorState);
        if((pfrAmt - Number(value.payAtFacilityAmt) - Number(value.payNowAmt)) < 0){
          throw new Error('Total payment amount cannot exceed pfr amount');
        }
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  const setEstimatorDescription = (estimatorBody: EstimatorBody) => {
    if (!estimatorBody.description || estimatorBody.description === "") {
      estimatorBody.description = `${vobPatientState.patient.firstName} ${vobPatientState.patient.lastName} - ${(new Date()).toLocaleDateString('en-US', {timeZone: "UTC"})}`
    }
    return estimatorBody;
  }

  const handleStripeStatus = (stripeStatus: boolean) => {
    setStripePaymentReadyStatus(stripeStatus);
  }

  const completePatientFormik = useFormik({
    initialValues: {
      patientTitle: selectedPatient?.contact?.title || "",
      patientFirstName: vobPatientState.patient.firstName,
      patientMI: vobPatientState.patient.middleName ? vobPatientState.patient.middleName : '',
      patientLastName: vobPatientState.patient.lastName,
      email: vobPatientState?.patient?.advisorPatientBody?.email? vobPatientState.patient.advisorPatientBody.email : "",
      phone1: vobPatientState?.patient?.advisorPatientBody?.phoneNumber? vobPatientState.patient.advisorPatientBody.phoneNumber : "",
      phone1Home: selectedPatient?.contact?.primPhoneType === PhoneType.mobile ? true : false,
      phone2: selectedPatient?.contact?.secPhoneNum || "",
      phone2Home: selectedPatient?.contact?.secPhoneType === PhoneType.mobile ? true : false,
      retypeEmail: vobPatientState?.patient?.advisorPatientBody?.email? vobPatientState.patient.advisorPatientBody.email : "",
      streetAddress1: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.streetAddress1? vobPatientState.patient.advisorPatientBody.primaryAddress.streetAddress1 : "",
      streetAddress2: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.streetAddress2? vobPatientState.patient.advisorPatientBody.primaryAddress.streetAddress2 : "",
      city: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.city? vobPatientState.patient.advisorPatientBody.primaryAddress.city : "",
      stateCode: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.state?.stateCode? vobPatientState.patient.advisorPatientBody.primaryAddress.state.stateCode : "",
      zip: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.zipCode? vobPatientState.patient.advisorPatientBody.primaryAddress.zipCode : "",
      timingRisk: vobPatientState?.patient?.selectedIOC?.timingRisk?.timingRiskId ? vobPatientState.patient.selectedIOC.timingRisk.timingRiskId : null, 
      payorRisk: vobState?.selfPay ? 3 : vobState?.payer?.inNetwork ? 1 : 2,
      isWarmTransfer: "",
      noteText: "",
      isPayNow: false,
      payNowAmt: "",
      isPayAtFacility: false,
      payAtFacilityAmt: "",
      useExistingSource: false,
      savePaymentMethod: false,
      nameOnCard: "",
      leaveVMFl: false,
      smsConsentFl: true
    },
    validate: checkIsFormValid,
    validationSchema: completePatientSchema,
    onSubmit: () => {},
  });

  const mapToPayment = (totalPaymentAmt: number, paymentProgramResponse: any) => {
    const scheduleId = paymentProgramResponse.selectedPatientEncounter.patientPaymentProgram[0].patientPaymentSchedule.patientPaymentScheduleId;
    const currentDateTime = new Date();
    return {
      isAuthExpired: false, 
      isAuthOnly: false, 
      isManual: true,
      patientEncounterId: patientEncounterResult?.patientEncounterId,
      patientId: selectedFinPassPatient?.patientId,
      patientPaymentScheduleId: scheduleId,
      paymentAmt: totalPaymentAmt,
      paymentGroupId: scheduleId.toString(),
      paymentId: 0, 
      paymentInitDt: currentDateTime.toISOString(),
      paymentMethods: [{
        externalPaymentMethodId: 'card',
        paymentMethodTypeId: 1,
        externalPaymentId: stripeToken,
        payorId: '',
        nameOnCard: completePatientFormik.values.nameOnCard
      }],
      paymentType: PAYMENT_TYPES.DOWN_PAYMENT,
      receiptEmail: completePatientFormik.values.email,
      paymentChannelId: 3,
      isFinAdvisor: true
    } as Payment;
  }

  const mapToPatientPaymentProgram = useCallback(() => {
    const pfrAmt = admissionsAdvisorUtils.getSelectedPFR(estimatorState);;
    const currentDate = new Date();
    const downPaymentAmt = 0;
    const terms = 0;
    const recurringPaymentAmt = 0;

    let patientPaymentSchedule: Partial<PatientPaymentSchedule>;

    if (patientEncounterResult?.patientPaymentProgram?.[0]?.patientPaymentSchedule) {
      const {
        downPmtAmt,
        paymentDueAmt,
        paymentFreq,
        pfrAmt: existingPfrAmt,
        remainingTerms,
        scheduleStartDt,
        scheduleStatus,
        terms: existingTerms
      } = patientEncounterResult.patientPaymentProgram[0].patientPaymentSchedule;
  
      patientPaymentSchedule = {
        downPmtAmt,
        paymentDueAmt,
        paymentFreq,
        pfrAmt: existingPfrAmt,
        remainingTerms,
        scheduleStartDt,
        scheduleStatus,
        terms: existingTerms
      };
    } else {
      patientPaymentSchedule = {
        pfrAmt: pfrAmt,
        paymentFreq: 'M',
        downPmtAmt: downPaymentAmt,
        terms: terms,
        remainingTerms: terms,
        paymentDueAmt: recurringPaymentAmt,
        scheduleStatus: 'Pending',
        scheduleStartDt: currentDate.toISOString(),
      };
    }

    const paymentProgram = {
      patientPaymentProgramId: patientEncounterResult?.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
      isHighRisk: false,
      isPaidInFull: false,
      downPmtAmt: downPaymentAmt,
      isACH: false,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram
    return paymentProgram;
  }, [patientEncounterResult])

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      { isLoading && 
        <Grid item xs={12} className="inline-modal-loader">
          <LoadingOverlay whiteBackground />
        </Grid>
      }
      <DialogTitle>Complete Patient Record</DialogTitle>
      <DialogContent>
        <p>
          Please complete the following form before submitting this estimate to
          FinPass.
        </p>
        <Typography variant="subtitle2" className="my-3">
          Name
        </Typography>
        <Grid container spacing={2}>
          <Grid item direction="column" md={2}>
            <TextField
              select
              label="Title"
              name="patientTitle"
              value={completePatientFormik.values.patientTitle}
              className="state-field"
              onChange={(e: Event) => {
                completePatientFormik.handleChange(e);
                //handleThirdPartyProviderChange(e);
              }}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.patientTitle &&
                completePatientFormik.errors.patientTitle
              }
            >
              {PatientTitles.map((provider: NameValue) => (
                <MenuItem key={provider.value} value={provider.value}>
                  {provider.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item direction="column" md={4} xs={4}>
            <TextField
              label="First Name"
              type="text"
              value={
                completePatientFormik.values.patientFirstName
                  ? completePatientFormik.values.patientFirstName
                  : ""
              }
              name="patientFirstName"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.patientFirstName &&
                completePatientFormik.errors.patientFirstName
                  ? completePatientFormik.errors.patientFirstName
                  : ""
              }
            />
          </Grid>
          <Grid item direction="column" md={2} xs={4}>
            <TextField
              label="M.I"
              type="text"
              value={
                completePatientFormik.values.patientMI
                  ? completePatientFormik.values.patientMI
                  : ""
              }
              name="patientMI"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.patientMI &&
                completePatientFormik.errors.patientMI
                  ? completePatientFormik.errors.patientMI
                  : ""
              }
            />
          </Grid>
          <Grid item direction="column" md={4} xs={4}>
            <TextField
              label="Last Name"
              type="text"
              value={
                completePatientFormik.values.patientLastName
                  ? completePatientFormik.values.patientLastName
                  : ""
              }
              name="patientLastName"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.patientLastName &&
                completePatientFormik.errors.patientLastName
                  ? completePatientFormik.errors.patientLastName
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{marginTop: "0.15em"}}>
          <Grid item direction="column" md={6}>
            <TextField
              label="Email"
              type="email"
              name="email"
              value={completePatientFormik.values.email}
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["email"] && completePatientFormik.errors["email"]
              }
            />
          </Grid>
          <Grid item direction="column" md={6}>
            <TextField
              label="Email"
              type="email"
              name="retypeEmail"
              value={completePatientFormik.values.retypeEmail}
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["retypeEmail"] && completePatientFormik.errors["retypeEmail"]
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{marginTop: "0.15em"}}>
          <Grid item direction="column" md={6}>
            <TextField
              label="Primary Phone"
              type="text"
              maxLength={12}
              value={
                completePatientFormik.values.phone1
                  ? completePatientFormik.values.phone1
                  : ""
              }
              name="phone1"
              onChange={(e: Event) => {
                completePatientFormik.handleChange(e);
                completePatientFormik.setFieldValue("phone1", Utils.formatPhoneNumber((e.target as HTMLInputElement).value));
              }}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.phone1 &&
                completePatientFormik.errors.phone1
                  ? completePatientFormik.errors.phone1
                  : ""
              }
            />
          </Grid>
          <Grid item direction="column" md={6}>
            <Toggle
              name="phone1Home"
              leftText="Mobile"
              rightText="Home"
              value={completePatientFormik.values.phone1Home}
              formik={completePatientFormik}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} sx={{marginTop: "0.15em"}}>
          <Grid item direction="column" md={6}>
            <TextField
              label="Alternate Phone"
              type="text"
              maxLength={12}
              value={
                completePatientFormik.values.phone2
                  ? completePatientFormik.values.phone2
                  : ""
              }
              name="phone2"
              onChange={(e: Event) => {
                completePatientFormik.handleChange(e);
                completePatientFormik.setFieldValue("phone2", Utils.formatPhoneNumber((e.target as HTMLInputElement).value));
              }}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched.phone2 &&
                completePatientFormik.errors.phone2
                  ? completePatientFormik.errors.phone2
                  : ""
              }
              required={false}
            />
          </Grid>
          <Grid item direction="column" md={6}>
            <Toggle
              name="phone2Home"
              leftText="Mobile"
              rightText="Home"
              value={completePatientFormik.values.phone2Home}
              formik={completePatientFormik}
            />
          </Grid>
        </Grid>
        <Grid
            container
            direction="row"
            spacing={2}
            sx={{marginTop: '0.15em', marginBottom: '18px'}}
        >
            <Grid xs={3} item style={{marginBottom: -12, paddingTop: 9}}>
                <Typography variant="h4">Leave Voicemail</Typography>
            </Grid>
            <Grid xs={9} item style={{marginBottom: -12, paddingTop: 9}}>
                <Typography variant="h4">Receive SMS Messages</Typography>
            </Grid>
            <Grid xs={3} item>
                <Toggle
                    name="leaveVMFl"
                    value={completePatientFormik.values.leaveVMFl}
                    formik={completePatientFormik}
                />
            </Grid>
            <Grid xs={9} item>
                <Toggle
                    name="smsConsentFl"
                    value={completePatientFormik.values.smsConsentFl}
                    formik={completePatientFormik}
                />
            </Grid>
        </Grid>
        <Typography variant="subtitle2" className="my-3">
          Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item direction="column" md={12}>
            <TextField
              label="Street Address 1"
              type="text"
              value={
                completePatientFormik.values.streetAddress1
                  ? completePatientFormik.values.streetAddress1
                  : ""
              }
              name="streetAddress1"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["streetAddress1"] &&
                completePatientFormik.errors["streetAddress1"]
                  ? completePatientFormik.errors["streetAddress1"]
                  : ""
              }
            />
          </Grid>
          <Grid item direction="column" md={12}>
            <TextField
              label="Street Address 2"
              type="text"
              value={
                completePatientFormik.values.streetAddress2
                  ? completePatientFormik.values.streetAddress2
                  : ""
              }
              name="streetAddress2"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["streetAddress2"] &&
                completePatientFormik.errors["streetAddress2"]
                  ? completePatientFormik.errors["streetAddress2"]
                  : ""
              }
              required={false}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} style={{marginTop: '2px'}}>
          <Grid item direction="column" md={4}>
            <TextField
              label="City"
              type="text"
              value={
                completePatientFormik.values.city
                  ? completePatientFormik.values.city
                  : ""
              }
              name="city"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["city"] &&
                completePatientFormik.errors["city"]
                  ? completePatientFormik.errors["city"]
                  : ""
              }
            />
          </Grid>
          <Grid item direction="column" md={4}>
            <TextField
              select
              label="State"
              name="stateCode"
              value={completePatientFormik.values.stateCode}
              onChange={(e: Event) => {
                completePatientFormik.handleChange(e);
                handleStateChange(e)
              }}
              className="state-field"
              onBlur={completePatientFormik.handleBlur}
            >
              {statesList.map((state: State) => (
                <MenuItem key={state.stateId} value={state.stateCode}>
                  {state.stateCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item direction="column" md={4}>
            <TextField
              label="Zip Code"
              type="text"
              value={
                completePatientFormik.values.zip
                  ? completePatientFormik.values.zip
                  : ""
              }
              name="zip"
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              error={
                completePatientFormik.touched["zip"] &&
                completePatientFormik.errors["zip"]
                  ? completePatientFormik.errors["zip"]
                  : ""
              }
              minLength={5}
              maxLength={5}
            />
          </Grid>
        </Grid>
        <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
          Additional Info
        </Typography>
        <Grid container spacing={2}>
          <Grid item direction="column" md={4} sm={4}>
            <TextField
              select
              error={
                completePatientFormik.touched["timingRisk"] &&
                completePatientFormik.errors["timingRisk"]
                  ? completePatientFormik.errors["timingRisk"]
                  : ""
              }
              label="Timing Risk"
              name="timingRisk"
              value={completePatientFormik.values.timingRisk}
              onChange={(e: Event) => {
                completePatientFormik.handleChange(e);
              }}
              onBlur={completePatientFormik.handleBlur}
            >
              {timingRisk.map((risk) => (
                <MenuItem key={risk.timingRiskId} value={risk.timingRiskId}>
                  {risk.timingRiskName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item direction="column" md={4} sm={4}>
            <TextField
              select={true}
              error={
                completePatientFormik.touched["payorRisk"] &&
                completePatientFormik.errors["payorRisk"]
                  ? completePatientFormik.errors["payorRisk"]
                  : ""
              }
              label="Payer Risk"
              name="payorRisk"
              value={completePatientFormik.values.payorRisk}
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
            >
              {payorRisk.map((payor: {payorRiskName: string, payorRiskId: number}) => (
                <MenuItem key={payor.payorRiskId} value={payor.payorRiskId}>
                  {payor.payorRiskName}
                </MenuItem>
              ))}
              </TextField>
          </Grid>

          <Grid item direction="column" md={4} sm={4}>
            <TextField
              error={
                completePatientFormik.touched["isWarmTransfer"] &&
                completePatientFormik.errors["isWarmTransfer"]
                  ? completePatientFormik.errors["isWarmTransfer"]
                  : ""
              }
              select={true}
              label='Interaction Type'
              name="isWarmTransfer"
              value={completePatientFormik.values.isWarmTransfer}
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
            >
              <MenuItem value={'Warm Transfer'}>Warm Transfer</MenuItem>
              <MenuItem value={'Inbound'}>Inbound Call</MenuItem>
              <MenuItem value={'Outbound'}>Outbound Call</MenuItem>
            </TextField>
          </Grid>

          <Grid item direction="column" md={12}  style={{marginTop: '6px'}}>
            <TextField
              error={
                completePatientFormik.touched["noteText"] &&
                completePatientFormik.errors["noteText"]
                  ? completePatientFormik.errors["noteText"]
                  : ""
              }
              label="Notes"
              name="noteText"
              multiline
              value={
                completePatientFormik.values.noteText
              }
              placeholder=""
              onChange={completePatientFormik.handleChange}
              onBlur={completePatientFormik.handleBlur}
              />
          </Grid>
        </Grid>
        <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
          Estimation Details
        </Typography>
          <EstDetails/>
          {finadvisorTakePayment && (
            <TakePaymentView formik={completePatientFormik} handleStripeStatus={handleStripeStatus} />
          )}
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={completePatientFormik.values.isPayNow ? (!displayPFRTotalError && stripePaymentReadyStatus && enableSaveButton) : enableSaveButton}
          savebuttonText="Save"
          saveStatus={iocSaveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={() => {}}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
